  .btn-wrap1 {
    position: fixed;
    bottom:40px;
	  left:100px;
    margin: -32px 0 0 -82px;
    z-index: 1;
}
 
.floating-btn1{

    display: block;
    max-width: 200px;
    font-weight: 700;
    background: var(--color-primary);
    position: relative;
    z-index: 1;
    padding: 10px 30px;
    color: white;
    margin: auto;
    box-shadow: 1px 2px 1px #615D6C;
    text-decoration: none;
    border-radius: 25px;
    animation: bounce 2s ease-in-out forwards;
    animation-iteration-count: infinite;

}

.btn-wrap1 a:hover{
    color: #fff;

}

.shadow1 {
    display: block;
    width: 20%;
    height: 0px;
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: auto;
    background: #6F8AB7;
    border-radius: 50%;
    animation: bounce-shadow 2s ease-in-out forwards;
    animation-iteration-count: infinite;
}
  
  
@keyframes bounce {
    0% {
      transform: translateY(0px); }
    50% {
      transform: translateY(20px); } }
  
  @keyframes bounce-shadow {
    0% {
      width: 1%;
      height: 2px;
      opacity: .1; }
    50% {
      width: 90%;
      height: 6px;
      opacity: 0.3; }
    100% {
      width: 1%;
      height: 0px;
      opacity: 0; } }
  
 