.about-us-area{
    padding: 60px 0px 100px;
    }
    .about-image {
    position: relative;
    }
    
    .about-shape1 {
        position: relative;
    }
    
    .about-shape1::before {
        position: absolute;
        content: "";
        background: var(--color-primary);
        width: 80px;
        height: 8px;
        top: 0px;
        right: 0px;
    }
    
    .about-shape1::after {
        position: absolute;
        content: "";
        background: var(--color-primary);
        width: 8px;
        height: 80px;
        top: 0px;
        right: 0px;
    }
    
    .about-shape11{
    position: relative;
    }
    
    .about-shape11::before {
        position: absolute;
        content: "";
        background: var(--color-secondary);
        width: 65px;
        height: 8px;
        top: 15px;
        right: 15px;
    }
    
    .about-shape11::after {
        position: absolute;
        content: "";
        background: var(--color-secondary);
        width: 8px;
        height: 65px;
        top: 15px;
        right: 15px;
    }
    
    .about-img1 {
        max-width: 65%;
    }
    
    .about-img2 {
        position: absolute;
        max-width: 65%;
        right: 0px;
        bottom: -145px;
        border-left: 0px solid rgb(255, 255, 255);
        border-top: 0px solid rgb(255, 255, 255);
    }
    
    .about-shape2{
    position: relative;
    margin-bottom: 100px;
    }
    
    .about-shape2::before {
        position: absolute;
        content: "";
        background: var(--color-primary);
        width: 80px;
        height: 8px;
        bottom: -146px;
        left: 0px;
    }
    
    .about-shape2::after {
        position: absolute;
        content: "";
        background: var(--color-primary);
        width: 8px;
        height: 80px;
        bottom: -146px;
        left: 0px;
    }
    
    .about-shape22{
    position: relative;
    margin-bottom: 160px;
    }
    
    .about-shape22::before {
        position: absolute;
        content: "";
        background: var(--color-secondary);
        width: 65px;
        height: 8px;
        bottom: -31px;
        left: 15px;
    }
    
    .about-shape22::after {
        position: absolute;
        content: "";
        background: var(--color-secondary);
        width: 8px;
        height: 65px;
        bottom: -31px;
        left: 15px;
    }
    
    .about-iconbox{
    margin-bottom:15px;
    }
    
    .about-iconbox .box-item {
        margin-bottom: 20px;
    }
    .about-iconbox .box-item .box-icon i {
        font-size: 24px;
        color: var(--color-primary);
        margin-right: 8px;
    }
    
    .about-iconbox .box-item .box-title h6 {
        color: rgb(24, 43, 73);
        font-weight: 600;
        margin-bottom: 6px;
    }
    
    .about-iconbox .box-item .box-title p {
        font-size: 14px;
        color: rgb(102, 102, 102);
    }
    
    .about-content .about-morebtn a {
        color: rgb(255, 255, 255);
        background-color: rgb(250, 73, 73);
        font-size: 16px;
        font-weight: 500;
        border-radius: 0px 25px;
        width: 145px;
        height: 45px;
        text-align: center;
        display: inline-block;
        padding-top: 11px;
        margin-right: 30px;
    }
    
    .about-content .about-morebtn i {
        font-size: 32px;
        color: var(--color-primary);
        width: 50px;
        height: 50px;
        text-align: center;
        padding-top: 11px;
        margin-right: 16px;
    }
    
    .about-content .about-morebtn p {
        font-size: 14px;
        color: rgb(150, 150, 150);
        font-weight: 500;
    }
    
    .about-content .about-morebtn h1 {
        color: rgb(24, 43, 73);
        font-weight: 600;
    }